import React from "react";
import { Link } from "react-router-dom";


import Logo from '../Assets/Images/logo-white.png';
import GooglePlay from '../Assets/Images/google-play-logo.png';
import AppStore from '../Assets/Images/app-store-logo.png';
import Location from '../Assets/Images/location-icon.png';
import Phone from '../Assets/Images/phone-icon.png';
import Envelope from '../Assets/Images/envelope-icon.png';


function Footer(props){
    return(
        <div className="footer" ref={props.kontakRef}>
            <div className="logo">
                <img src={Logo} width="138" height="81" alt="logo" />
            </div>
            <div className="content">
                <div className="logoPublish">
                    <a href="https://play.google.com/store/apps/details?id=com.shira" target="_blank" ><img src={GooglePlay} alt="Google Play" /></a>
                    <img src={AppStore} alt="App Store" />
                </div>
                <div className="menu">
                    <Link onClick={props.tentang} to="/">Tentang Kami</Link>
                    {/* <Link to="/">Partnership</Link> */}
                    <Link onClick={props.faq} to="/">FAQ</Link>
                    {/* <Link to="/">Blog</Link> */}
                    <Link to="/">Help & Support</Link>
                </div>
                <div className="menu">
                    {/* <Link to="/">Panduan Perusahaan</Link> */}
                    <Link to="/privacy" target="_top">Kebijakan Privasi</Link>
                    <Link to="/">Syarat dan Ketentuan</Link>
                </div>
                <div className="contactUs">
                    <div className="subContactUs">
                        <img src={Location} width="30" height="30" alt="Location" />
                        <h5>PT. SINTERA TEKNO UTAMA Rukan Exclusive Radin Inten Kav. 18, Jl. Radin Inten II No. 80, Duren Sawit, Jakarta Timur 13440</h5>
                    </div>
                    <div className="subContactUs">
                        <img src={Phone} width="30" height="30" alt="Location" />
                        <h5>021 – 311 51 783</h5>
                    </div>
                    <div className="subContactUs">
                        <img src={Envelope} width="30" height="30" alt="Location" />
                        <h5>Marketing@sintera.id</h5>
                    </div>
                </div>
            </div>
            <div className="copyright">
                <hr />
                <h4>All Right Reserved - PT SINTERA TEKNO UTAMA, 2023</h4>
            </div>
        </div>
    )
}

export default Footer;