import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Navigations from "../Components/Navigations";
import Footer from "../Components/Footer";
import Faq from "../Components/Faq";

import BackgroundHeader from '../Assets/Images/bg.png';
import IphoneView1 from '../Assets/Images/phone-1.png';
import IphoneView2 from '../Assets/Images/phone-2.png';
import ScheduleCard from '../Assets/Images/schedule-card.png';
import SummaryCard from '../Assets/Images/summary-card.png';
import GooglePlay from '../Assets/Images/google-play-logo.png';
import AppStore from '../Assets/Images/app-store-logo.png';
import Logo from '../Assets/Images/logo-white.png';
import Feature1 from '../Assets/Images/feature-1.png';
import Feature2 from '../Assets/Images/feature-2.png';
import Feature3 from '../Assets/Images/feature-3.png';
import Illustration1 from '../Assets/Images/illustration-1.png';
import Illustration2 from '../Assets/Images/illustration-2.png';
import Illustration3 from '../Assets/Images/illustration-3.png';
import Laptop from '../Assets/Images/laptop.png';
import ShiraIcon from '../Assets/Images/shira-icon.png';
// import CaretRightIcon from '../Assets/Images/caret-right-icon.png';
import Phone from '../Assets/Images/phone-3.png';
import Whatsapp from '../Assets/Images/whatsapp-icon.png';
import Flag from '../Assets/Images/flag.png';



function Homepage(){

    useEffect(() => {
        beranda.current.scrollIntoView({ behavior: "smooth" });
    }, []);

    const beranda = useRef();
    const tentang = useRef();
    const fitur = useRef();
    const faq = useRef();
    const kontak = useRef();
    const hubungi = useRef();

    function linkBeranda(){
        beranda.current.scrollIntoView({ behavior: "smooth" });
    }

    function linkTentang(){
        tentang.current.scrollIntoView({ behavior: "smooth"});
    }

    function linkFitur(){
        fitur.current.scrollIntoView({ behavior: "smooth"});
    }

    function linkFaq(){
        faq.current.scrollIntoView({ behavior: "smooth" });
    }

    function linkKontak(){
        kontak.current.scrollIntoView({ behavior: "smooth" });
    }

    function linkHubungi(){
        hubungi.current.scrollIntoView({ behavior: "smooth" });
    }

    const faqData = [
        {
          title: 'Apa itu SHIRA ?',
          content: `SHIRA adalah aplikasi HRIS berbasis Cloud yang mengintegrasikan data karyawan, data absensi, dan data payroll, untuk menghasilkan laporan yang komprehensif termasuk laporan pajak Anda. SHIRA dapat membantu perusahaan dalam pengelolaan karyawan secara akurat, tepat dan cepat dengan berbagai fitur yang akan memudahkan Tim HR dalam prosesnya.`
        },
        {
          title: 'Apa fitur unggulan SHIRA ?',
          content: `Geo Fencing, Face Recognition, 100% Mobile, Employe Self Services.`
        },
        {
          title: 'Apakah SHIRA dapat terhubung dengan payroll system ?',
          content: `Dengan SHIRA Integrated Payroll System tidak ada lagi kerumitan terkait pengaturan penggajian dan perpajakan. Anda dapat dengan mudah membuat dan mengubah banyak hal secara fleksibel seperti halnya komponen gaji karyawan, perhitungan pajak (PPH21), kebijakan jaminan sosial (BPJS), Cut-Off pembayaran, dan banyak lagi. Selesaikan perhitungan gaji perusahaan Anda hanya dalam hitungan menit.`
        },
        {
            title: 'Bagaimana SHIRA dapat menghimpun semua kebutuhan HR ?',
            content: `Fitur Real-Time Report memudahkan pengguna, baik karyawan maupun Tim HR untuk melihat aktivitas absensi, pengajuan cuti/ijin, slip gaji, dan lainnya secara cepat dan akurat. 

            SHIRA memberikan Real-Time Report yang akurat dan komprehensif, sehingga memudahkan pengolahan data dan informasi karyawan dengan tujuan untuk dapat meningkatkan produktivitas serta efisiensi perusahaan secara keseluruhan.`
          }
      ];
    

    return(
        <div>
            <Navigations
              beranda={linkBeranda}  
              tentang={linkTentang}  
              fitur={linkFitur}  
              faq={linkFaq}  
              kontak={linkKontak}  
              hubungi={linkHubungi}  
            ></Navigations>
            <div className="container">
                <div className="beranda"  ref={beranda}>
                    <div className="content">
                        <h1><span className="text1">Sintera HRIS</span><br /> <span className="text2">& Payroll Application</span></h1>
                        <div className="tagLine"><h3>Mengoptimalkan kinerja TIM HR dalam mengelola karyawan</h3></div>
                        <div className="logoPublish">
                            <a href="https://play.google.com/store/apps/details?id=com.shira" target="_blank" ><img src={GooglePlay} alt="Google Play" /></a>
                            <img src={AppStore} alt="App Store" />
                        </div>
                        <div className="pageButton">
                            <button onClick={linkTentang} className="button primary">Pelajari Selengkapnya</button>
                        </div>
                    </div>
                    <div className="screenApp">  
                        <img className="imageIphone1" src={IphoneView1} alt="Iphone Shira"></img>
                        <img className="imageIphone2" src={IphoneView2} alt="Iphone Homepage" />
                        <img className="imageSchedule" src={ScheduleCard} alt="Schedule Card" />
                        <img className="imageSummary" src={SummaryCard} alt="Summary Card" />
                    </div>
                    <img className="imageBackground" src={BackgroundHeader} alt="Background Header" />
                </div>
                <div className="sectionTentang" ref={tentang}>
                    {/* <img src={Rope} className="rope" alt="Rope" /> */}
                    <img src={Logo} className="imageLogo" alt="Logo Shira" />
                    <div className="content">
                        <h2>Tentang Kami</h2>
                        <h4><span style={{fontSize: 20, color: "white", fontWeight: 700}}>SHIRA</span> adalah aplikasi HRIS berbasis Cloud yang mengintegrasikan data karyawan, data absensi, dan data Payroll, untuk menghasilkan laporan yang komprehensif termasuk laporan pajak Anda.</h4>
                    </div>
                    <div className="flag">
                        <img src={Flag} width="56" height="39" alt="Flag" />
                    </div>
                </div>
                <div className="sectionFitur" ref={fitur}>
                    <div className="headerText">
                        <h2>Fitur & Benefit</h2>
                    </div>
                    <div className="subHeaderText">
                        <h4><span className="textBold">SHIRA</span> dapat membantu perusahaan dalam pengelolaan karyawan secara akurat, tepat dan cepat dengan berbagai fitur yang akan memudahkan Tim HR dalam prosesnya.</h4>
                    </div>
                    <div className="content">
                        <div className="featurepage">
                            <div className="feature">
                                <div className="imageFeature">
                                    <img src={Feature3} alt="Geo Fencing" />
                                </div>
                                <div className="title">Geo Fencing</div>
                                <div className="textContent">Memungkinkan system absensi mengukur kehadiran karyawan secara akurat berdasarkan lokasi kerja yang telah ditentukan.</div>
                            </div>
                            <div className="feature">
                                <div className="imageFeature">
                                    <img src={Feature1} alt="Face Recognition" />
                                </div>
                                <div className="title">Face Recognition</div>
                                <div className="textContent">Dengan Face Recognition proses absensi menjadi lebih nyaman tanpa khawatir karena kartu identitas tertinggal, atau sidik jari yang sulit terbaca.</div>
                            </div>
                            <div className="feature">
                                <div className="imageFeature">
                                    <img src={Feature2} alt="100% Mobile" />
                                </div>
                                <div className="title">100% Mobile</div>
                                <div className="textContent">Aplikasi absensi mobile fleksibel dan dapat dilakukan tanpa perangkat tambahan. Data aplikasi langsung disimpan ke dalam Cloud Server secara Real-Time.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sectionBenefit">
                    <div className="subSection">
                        <div className="contentImage">
                            <img src={Illustration1} className="imgIllustration1" alt="List Task" />
                        </div>
                        <div className="contentText">
                            <div className="header"><h2>Employment Self-Service</h2></div>
                            <div className="content">
                                <h4>Dengan fitur <span className="textBold">Employee Self-Service</span> Anda dapat lebih menghemat waktu, dan menjadikan data lebih aman dan akurat.</h4>
                                <div className="point">
                                    <img src={ShiraIcon} width="20" height="20" alt="Shira Icon" />
                                    <h4>Proses input, rekap, dan olah data kehadiran oleh <span className="textBold">Tim HR</span> menjadi lebih mudah karena terintegrasi secara otomatis. Pada system manual proses input data dapat menghabiskan waktu lama, apalagi bila perusahaan memiliki karyawan dalam jumlah banyak dapat meningkatkan potensi kesalahan input.</h4>
                                </div>
                                <div className="point">
                                    <img src={ShiraIcon} width="20" height="20" alt="Shira Icon" />
                                    <h4>Pengajuan dan Approval perijinan, sakit, cuti, serta lembur dilakukan dengan cepat dan dapat diajukan dari mana dan kapan saja. Apabila terdapat kendala dalam prosesnya karyawan dapat melakukan ralat mandiri atas persetujuan HR.</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="subSection">
                        <div className="contentImageAbs">
                            <img src={Laptop} width="300" height="300" alt="List Task" />
                        </div>
                        <div className="divider1"></div>
                        <div className="contentText">
                            <div className="header"><h2>Integrated Payroll System</h2></div>
                            <div className="content">
                                <h4>Pengaturan fleksibel untuk manfaat dan pengurangan sesuai dengan kebutuhan Anda. Metode perhitungan dan laporan pajak <span className="textBold">(e-SPT)</span> untuk setiap karyawan.</h4>
                                <div className="point">
                                    <img src={ShiraIcon} width="20" height="20" alt="Shira Icon" />
                                    <h4>Dengan <span className="textBold">SHIRA</span> Integrated Payroll System tidak ada lagi kerumitan terkait pengaturan penggajian dan perpajakan. Anda dapat dengan mudah membuat dan mengubah banyak hal secara fleksibel seperti halnya komponen gaji karyawan, perhitungan pajak <span className="textBold">(PPH21)</span>, kebijakan jaminan sosial (BPJS), Cut-Off pembayaran, dan banyak lagi. Selesaikan perhitungan gaji perusahaan anda hanya dalam hitungan menit.</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="subSection">
                        <div className="contentImage">
                            <img src={Illustration2} className="imgIllustration2" alt="Report" />
                        </div>
                        <div className="contentText">
                            <div className="header"><h2>Real-Time Report</h2></div>
                            <div className="content">
                                <h4>Fitur <span className="textBold">Real-Time Report</span> memudahkan pengguna, baik karyawan maupun <span className="textBold">Tim HR</span> untuk melihat aktivitas absensi, pengajuan cuti/ijin, slip gaji, dan lainnya secara cepat dan akurat.</h4>
                                <div className="point">
                                    <img src={ShiraIcon} width="20" height="20" alt="Shira Icon" />
                                    <h4><span className="textBold">SHIRA</span> memberikan Real-Time Report yang akurat dan komprehensif, sehingga memudahkan pengolahan data dan informasi karyawan dengan tujuan untuk dapat meningkatkan produktivitas serta efisiensi perusahaan secara keseluruhan.</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sectionFaq" ref={faq}>
                    <div className="subSection">
                        <div className="contentImageAbs2">
                            <img src={Illustration3} className="imgIllustration3" alt="Faq" style={{borderTopLeftRadius: "8px", borderBottomLeftRadius: "8px"}}/>
                        </div>
                        <div className="divider2"></div>
                        <div className="contentText">
                            <div className="header"><h2 style={{color: "white", textAlign: "center"}}>FAQ</h2></div>
                            <div className="content">
                                <div className="listQuestion">
                                    {faqData.map(({title, content}) => (
                                        <Faq title={title} content={content}></Faq>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sectionHubungi" ref={hubungi}>
                    <div className="subSection">
                        <div className="contentImageAbs2">
                            <img src={Phone} className="imgIllustration4" alt="Phone"/>
                            <img src={Laptop} className="imgIllustration5" alt="Laptop"/>
                        </div>
                        <div className="divider3"></div>
                        <div className="contentText">
                            <div className="header">
                                <h3 style={{color: "black", textAlign: "left"}}>Solusi Terbaik Untuk Kebutuhan HR</h3>
                            </div>
                            <div className="content">
                                <h4>Mulai uji coba demo sekarang!</h4>
                                <div className="pageButton">
                                    <button className="button icon">
                                        <img src={Whatsapp} width="20" height="20" alt="WhatsApp" />
                                        Hubungi Sales
                                    </button><br />
                                </div>
                                <h5>Submit e-mail mu untuk mendapatkan update newsletter, promo dan lain-lain.</h5><br />
                                <div className="inputWithButton">
                                    <input type="text" placeholder="Masukkan email"/>
                                    <button>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer
                    kontakRef={kontak} 
                    tentang={linkTentang}  
                    faq={linkFaq} 
                ></Footer>
            </div>
        </div>
    )
}

export default Homepage;