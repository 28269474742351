import React, { useState } from 'react';

import CaretRightIcon from '../Assets/Images/caret-right-icon.png';

const Faq = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="question">
      <div className="questionTitle" onClick={() => setIsActive(!isActive)}>
        <h4>{title}</h4>
        <img src={CaretRightIcon} width="12" height="20" alt="Caret Right" />
      </div>
      {isActive && <div className="panel">{content}</div>}
    </div>
  );
};

export default Faq;