import './Assets/Scss/App.scss';

//import Pages
import Homepage from './Pages/Home';

function App() {
  return (
    <div className="App">
      <Homepage></Homepage>
    </div>
  );
}

export default App;
