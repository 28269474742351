import React from "react";

import Navigations from "../Components/Navigations";
import Footer from "../Components/Footer";

import Phone from '../Assets/Images/phone-1.png';


function Privacy(){
    return(
        <div>
            <Navigations></Navigations>
            <div className="containerPrivacy">
                <div className="headerPrivacy">
                    <img src={Phone} alt="Shira" />
                    <h2>Kebijakan Privasi</h2>
                </div>
                <div className="contentPrivacy">
                    <h3>KEBIJAKAN PRIVASI SHIRA</h3>
                    
                    <p>Kebijakan privasi ini (selanjutnya disebut “Kebijakan”) mengatur tentang cara SHIRA mengumpulkan dan menggunakan data Anda dalam produk dan layanan yang Kami kembangkan (selanjutnya disebut “Produk”). Dengan menggunakan produk dan atau layanan SHIRA, Anda telah menyetujui dan terikat dengan hal-hal yang telah terdapat dalam kebijakan ini. Oleh karena itu, Kami berharap Anda membaca kebijakan ini untuk memastikan bahwa Anda mengerti bagaimana cara Kami menangani data Anda.</p>
                    <p>Untuk tujuan kebijakan ini, harap diperhatikan bahwa:</p>
                    <ol>
                        <li><span className="textBold">SHIRA</span> mengacu pada PT. SINTERA TEKNO UTAMA dan perusahan yang terkait.</li>
                        <li>Kami/Milik Kami mengacu pada SHIRA dan masing-masing karyawan atau agennya baik yang berlokasi di dalam  ataupun di luar Indonesia.</li>
                        <li>Layanan mengacu pada produk dan layanan <span className="textBold">SHIRA</span> yang telah Anda ajukan atau gunakan, namun tidak terbatas pada layanan penggajian dan kepegawaian, perangkat lunak, produk, dan dukungan yang disediakan oleh <span className="textBold">SHIRA</span>.</li>
                    </ol>
                    <p>Data pribadi dalam kebijakan ini mencakup data apapun tentang individu yang dapat diidentifikasikan dari data tersebut, seperti nama individu tersebut, paspor atau nomor identifikasi lainnya, kontak, pengalaman kerja, kualifikasi, hasil test bakat, rincian penggajian, insiden di tempat kerja, dan informasi lain yang diperoleh atau diterima oleh Kami sehubungan dengan layanan.</p>

                    <h3>PENGGUNAAN DATA PRIBADI</h3>

                    <ol type="1">
                        <li>Kami dapat mengumpulkan, menggunakan, dan mengungkapkan data pribadi karyawan, direktur, pemilik manfaat, pemegang saham, dan perwakilan resmi Anda untuk satu atau lebih tujuan berikut:</li>
                        <ol type="a">
                            <li>Untuk menetapkan identitas dan latar belakang mereka;</li>
                            <li>Memproses permintaan untuk produk dan atau layanan apapun yang ditawarkan atau didistribusikan oleh Kami (termasuk namun tidak terbatas pada produk pihak ketiga);</li>
                            <li>Menyediakan produk dan layanan yang diminta oleh Anda atau individu yang terkait dengan organisasi Anda;</li>
                            <li>Menghasilkan laporan pemasaran, peraturan, manajemen, statistik, atau terkait lainnya dan melakukan analisis;</li>
                            <li>Memenuhi atau mematuhi kebijakan dan prosedur internal Kami (atau organiasi pihak ketiga afiliasi Kami) serta hukum atau perintah pengadilan yang berlaku;</li>
                            <li>Tujuan hukum;</li>
                            <li>Memberikan informasi perkembangan produk dan/atau layanan, perawatan, atau gangguan apapun yang mungkin Anda hadapi;</li>
                            <li>Memfasilitasi penugasan, transfer, partisipasi ataupun sub-partisipasi sesuai hak dan kewajiban dalam kerja sama kita;</li>
                            <li>Untuk surat menyurat, pengiriman, ataupun proposal yang memerlukan pengungkapan informasi Data Pribadi Anda;</li>
                            <li>Untuk pemasaran dan pembangan bisnis Kami kepada Anda dan karyawan  Anda (termasuk namun tidak terbatas pada produk pihak ketiga);</li>
                            <li>Untuk tujuan bisnis internal lainnya agar Kami dapat meningkatkan layanan Kami kepada Anda dan individu yang terkai dengan organisasi Anda;</li>
                            <li>Melakukan riset pasar, survey, dan analisis data terkait dengan layanan dan atau produk apapun yang kami sediakan (baik dilakukan bersama pihak lain maupun tidak) yang mungkin relevan bagi Anda atau individu yang terkait dengan organisasi Anda;</li>
                            <li>Tujuan lain yang diperlukan terkait dengan tujuan di atas.</li>
                        </ol>
                        <li>Data Pribadi individu dijaga kerahasiaannya. Namun untuk dapat melaksanakan tujuan yang tercantum di atas, <span className="textBold">SHIRA</span> dapat membagikan Data Pribadi tersebut dengan perusahaan, entitas, dan kontraktor terkait atau afiliasinya baik di Indonesia ataupun di tempat lain. Saat melakukan hal tersebut, Kami mewajibkan mereka untuk memastikan bahwa Data Pribadi yang diungkapkan dijaga kerahasiaan dan keamanannya.</li>
                        <p>Harap dicatat bahwa dukungan teknis atau dukungan pelanggan yang melibatkan akses ke Data Pribadi dapat diberikan oleh perwakilan <span className="textBold">SHIRA</span> yang berlokasi di luar Indonesia.</p>
                        <li>Apabila Anda atau individu yang terkait dengan organisasi Anda mengajukan atau memiliki produk dan atau layanan yang ditawarkan oleh <span className="textBold">SHIRA</span> dan mitra dagang, <span className="textBold">SHIRA</span> juga dapat mengumpulkan, menggunakan, dan mengungkapkan Data Pribadi untuk tujuan berbagi informasi dengan mitra dagang dalam menawarkan, memasarkan, dan mempromosikan kepada Anda produk, layanan, penawaran, atau cara apapun yang menurut mitra dagang mungkin menarik bagi Anda atau individu tersebut.</li>
                    </ol>

                    <h3>PENGUNGKAPAN DATA PRIBADI</h3>
                    <ol type="1">
                        <li>Kami dapat mengungkapkan Data Pribadi individu yang terkait dengan organisasi Anda kepada </li>
                            <ol type="a">
                                <li>Mitra dagang SHIRA dan pihak ketiga terkait atau terpercaya;</li>
                                <li>Asosiasi atau badan profesional yang mempunyai kepentingan sah dalam pengungkapan Data Pribadi orang tersebut;</li>
                                <li>Penyedia Pendidikan atau pelatihan eksternal untuk individu yang terkait dengan organisasi Anda;</li>
                                <li>Karyawan dan agen <span className="textBold">SHIRA</span> yang berlokasi di dalam dan di luar Indonesia untuk membantu Kami dalam menyediakan layanan.</li>
                            </ol>
                        <li>Kami akan memperlakukan Data Pribadi tersebut sebagai pribadi dan rahasia dan diluar pihak-pihak yang disebutkan di atas, Kami tidak akan mengungkapkan data tersebut kepada pihak lain manapun kecuali:</li>
                            <ol type="a">
                                <li>Dimana izin telah diberikan oleh individu tersebut;</li>
                                <li>Apabila kita diwajibkan atau diizinkan untuk melakukan hal tersebut secara hukum;</li>
                                <li>Jika diperlukan  atau diizinkan oleh perintah pengadilan apapun;</li>
                                <li>Dimana Kami diwajibkan untuk memenuhi kewajiban Kami terhadap otoritas regulasi terkait.</li>
                            </ol>
                    </ol>
                    
                    <h3>KEAMANAN DATA PRIBADI</h3>
                    
                    <ol type="1">
                        <li><span className="textBold">SHIRA</span> menangani pelanggaran privasi dengan sangat serius. Karyawan dan kontraktor kami dilatih untuk dapat menangani Data Pribadi secara aman, sangat menghargai kerahasiaan. Jika tidak, mereka dapat dikenakan tindakan disipliner. Karyawan dan kontraktor <span className="textBold">SHIRA</span> yang berada di luar Indonesia secara kontrak berkewajiban untuk menjaga keamanan Data Pribadi apapun yang mungkin mereka tangani.</li>
                        <li>Kami menyimpan Data Pribadi dalam fasilitas penyimpanan komputer yang aman, file berbasis kertas, dan metode pencatatan lainnya, mengambil langkah-langkah yang diperlukan untuk melindungi Data Pribadi yang Kami simpan dari penyalahgunaan, kerusakan dan kehilangan, akses yang tidak sah, perubahan atau pengungkapan. Kami mungkin perlu menyimpan catatan tersebut dalam jangka waktu yang cukup lama. Namun, ketika kami anggap informasi tidak lagi diperlukan, Kami akan menghapus seluruh informasi yang dapat mengidentifikasi seseorang dan/atau menghancurkan seluruh catatan Data Pribadi tersebut.</li>
                        <li>Kami telah menunjuk penanggung jawab perlindungan data untuk memastikan bahwa Data Pribadi dikelola sesuai dengan kebijakan ini, aturan perlindungan Data Pribadi, dan aturan serupa lainnya yang berlaku di negara tempat Kami beroperasi.</li>
                    </ol>

                    <h3>AKURASI DATA PRIBADI</h3>

                    <ol type="1">
                        <li>Kami mengambil langkah-langkah yang diperlukan untuk memastikan bahwa semua Data Pribadi adalah yang terbaru dan akurat, serta menyadari bahwa rincian kontak dan data lainnya dapat sering berubah. Harap sampaikan kepada Kami apabila perubahan informasi.</li>
                        <li>Jika seseorang yang terkait dengan organisasi Anda ingin menghapus Data Pribadi yang mereka simpan bersama Kami, dapat menghubungi Tim Support Kami. SHIRA dapat menyimpan informasi tertentu tentang seseorang jika diwajibkan oleh hukum.</li>
                    </ol>
                    <h3>HAK AKSES DAN PERUBAHAN DATA PRIBADI</h3>
                    <ol type="1">
                        <li>Kami dapat membantu Anda mengakses dan merubah Data Pribadi yang Kami simpan.</li>
                        <li>Untuk membuat permintaan akses Data Pribadi, atau jika Anda merasa Data Pribadi yang Kami simpan tidak akurat, tidak lengkap, atau menyesatkan, individu yang terkait dengan data tersebut perlu melengkapi formulir permintaan yang menetapkan Data Pribadi apa saja yang diperlukan dan juga memastikan identitas mereka. Silahkan menghubungi Tim Support Kami untuk formulir permintaan atau informasi lebih lanjut tentang cara akses Data Pribadi Anda.</li>
                        <li>Kami akan menanggapi setiap permintaan dalam kurun waktu 30 hari setelah formulir permintaan yang lengkap Kami terima dan memberikan individu terkait akses ke Data Pribadi mereka. Jika Kami tidak dapat memenuhi permintaan tersebut dalam kurun waktu ini, Kami akan memberikan saran dan solusi terbaik dalam batas kemampuan Kami. Mohon diperhatikan bahwa mungkin diperlukan waktu lebih lama untuk memproses permintaan karena perlu mengambil informasi dari media penyimpanan dan melakukan verifikasi terlebih dahulu. Mungkin juga terdapat biaya yang diperlukan untuk permintaan akses, silahkan diskusikan hal ini dengan tim support Kami.</li>
                        <li>Mohon diperhatikan bahwa Kami mungkin perlu menunda akses ke Data Pribadi dalam situasi tertentu, misalnya ketika Kami tidak dapat mengkonfirmasi identitas individu yang meminta data tersebut.</li>
                        <li>Seseorang dapat meminta agar Kami memperbaiki Data Pribadi yang Kami miliki tentang mereka jika dianggap tidak akurat, tidak lengkap, kadaluwarsa, tidak relevan, atau menyesatkan. Kami akan mengambil langkah-langkah yang diperlukan untuk memperbaiki data tersebut sesegera mungkin.</li>
                    </ol>

                    <h3>PENGENALAN WAJAH</h3>

                    <p><span className="textBold">SHIRA</span> mengumpulkan informasi yang dapat membantu perusahaan untuk memastikan bahwa pengguna yang mencatatkan waktu kehadirannya adalah pengguna yang seharusnya (tidak diwakili oleh orang lain). Pengumpulan informasi meliputi:</p>
                    <p><span className="textBold">SHIRA</span> membutuhkan data pengguna yang terdiri dari foto profil dan foto kehadiran beserta turunannya. Untuk dapat mendeteksi apakah pengguna mencatat waktu kehadiran yang seharusnya dilakukan oleh pengguna. Untuk menggunakan sistem pengenalan wajah <span className="textBold">SHIRA</span> tidak menggunakan pihak ketiga, dan data disimpan dengan aman dalam server dengan metode enkripsi atau metode lain. Data pengguna yang tersimpan dalam database <span className="textBold">SHIRA</span> hanya akan digunakan sesuai kebutuhan dalam aplikasi dan <span className="textBold">SHIRA</span> akan menyimpan data pengguna selama pengguna masih menggunakan layanan ini.</p>
                    <p>Berikut tahap pengolahan data pengguna:</p>
                    <p>- Pengguna memasukkan foto kehadiran saat pertama kali daftar yang akan muncul pada dashboard dan diverifikasi oleh admin <span className="textBold"></span>SHIRA;</p>
                    <p>- Foto absensi yang akan dicocokkan disimpan dalam server <span className="textBold">SHIRA</span> dan hanya akan digunakan untuk memverifikasi pengguna saat akan memulai kerja. Informasi yang dikumpulkan digunakan untuk identifikasi saja, apakah pengguna yang mencatatkan waktu kehadirannya merupakan pengguna yang seharusnya (sesuai dengan data karyawan yang telah dimasukkan ke dalam sistem).</p>

                    <h3>PERSETUJUAN</h3>
                    <ol type="1">
                        <li>Jika Anda tidak setuju dengan kebijakan ini, mohon JANGAN terus menggunakan atau mengakses layanan.</li>
                        <li>Dengan mengungkapkan atau memberikan kepada Kami Data Pribadi yang berkaitan dengan individu dalam organisasi Anda (seperti karyawan), Anda menyatakan dan menjamin bahwa:</li>
                        <ol type="a">
                            <li>Persetujuan dari individu tersebut telah diperoleh untuk pengumpulan, penggunaan, dan pengungkapan Data Pribadi untuk tujuan yang tercantum di atas;</li>
                            <li>Anda setuju dengan kebijakan ini; dan</li>
                            <li>Anda telah memperoleh persetujuan yang jelas dan tidak ambigu dari individu tersebut bahwa mereka dapat dihubungi oleh Kami atau afiliasi Kami melalui nomor telepon mereka sehubungan dengan pemasaran atau tujuan lainnya.</li>
                        </ol>
                        <li>Jika individu yang berada dalam kebijakan ini menarik persetujuannya, Anda setuju untuk segera memberitahu Kami secara tertulis maksimal 5 (lima) hari kerja. Akibat dari penarikan persetujuan tersebut, <span className="textBold">SHIRA</span> mungkin tidak dapat terus menyediakan produk atau layanan Kami kepada Anda. Penarikan tersebut dapat dianggap sebagai pengakhiran oleh Anda atas hubungan kontraktual apapun yang mungkin Anda miliki dengan Kami dan pelanggaran terhadap kewajiban atau perjanjian kontrak Anda, dan hak serta upaya hukum Kami dalam hal tersebut secara tegas dilindungi oleh undang-undang.</li>
                    </ol>

                    <h3>PENGGUNAAN <span className="textItalic">COOKIES</span></h3>
                    <ol type="1">
                        <li>Kami menggunakan <span className="textItalic">cookies</span> pada situs web Kami. <span className="textItalic">Cookies</span> adalah <span className="textItalic">file</span> yang Kami masukkan ke dalam komputer Anda untuk mencatat informasi tentang kunjungan Anda dan penggunaan situs web guna meningkatkan pengalaman Anda di situs web.</li>
                        <li>Kami menggunakan <span className="textItalic">cookies</span> untuk tujuan berikut:</li>
                        <ol type="a">
                            <li>Meningkatkan pengalaman pengguna di situs web;</li>
                            <li>Membantu Kami dalam memantau kinerja situs web;</li>
                            <li>Mengingat preferensi Anda;</li>
                            <li>Memantau lalu-lintas situs web;</li>
                            <li>Membantu situs web agar dapat berfungi dengan baik.</li>
                        </ol>
                        <li>Anda dapat menonaktifkan dan mengaktifkan <span className="textItalic">cookies</span> melalui pengaturan <span className="textItalic">browser</span> web Anda. Jika Anda menonaktifkan atau memblokir <span className="textItalic">cookies</span> di situs web Kami, Anda mungkin tidak dapat mengakses seluruh cakupan fungsi yang biasanya dilakukan oleh situs web Kami.</li>
                        <li>Data Pribadi Anda yang Kami kumpulkan melalui <span className="textItalic">cookies</span> pada web Kami dapat diteruskan ke penyedia layanan pihak ketiga (baik di dalam maupun di luar Indonesia), untuk mengelola atau meningkatkan situs web Kami, untuk penyimpanan dan backup data, atau untuk satu atau lebih tujuan yang sudah disebutkan pada poin 2 di atas.</li>
                        <li>Penggunaan terhadap situs web Kami merupakan persetujuan terhadap penggunaan <span className="textItalic">cookies</span> oleh Kami sebagaimana diatur dalam kebijakan ini.</li>
                    </ol>
                    
                    <h3>PERTANYAAN, PERMINTAAN, DAN KELUHAN</h3>
                    <p>Apabila Anda ingin mengajukan keluhan tentang data Anda, atau Anda memiliki pertanyaan lain mengenai privasi atau keamanan data Anda, silahkan menghubungi Kami melalui:</p>
                    <table>
                        <tr>
                            <td><ul><li>Email</li></ul></td>
                            <td>:</td>
                            <td>cs@sintera.id</td>
                        </tr>
                        <tr>
                            <td><ul><li>Telepon</li></ul></td>
                            <td>:</td>
                            <td>(021) 31151785</td>
                        </tr>
                        <tr>
                            <td><ul><li>Kantor</li></ul></td>
                            <td>:</td>
                            <td>Rukan Exclusive Commercial Radin Inten Kav. 18, Jl. Radin Inten II No. 80, Duren Sawit, Jakarta Timur 13440</td>
                        </tr>
                    </table>
                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}

export default Privacy;